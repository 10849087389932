import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DBModel, DBFunction, DBDevice } from '../../data/endooSpotDB_objects';
import { EndooHttpService } from 'app/Services/endooHttp.service';

@Injectable()
export class ModelService {
    private readonly modelURL = '/api/models/';
    models: DBModel[] = [];

    constructor(private http: EndooHttpService) { }

    init(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.getAll().subscribe(models => {
                this.models = models;
                resolve();
            });
        });
    }

    /**
     * Function which returns all models from Server
     */
    getAll(): Observable<DBModel[]> {
        return this.http.get<DBModel[]>(this.modelURL, { observe: 'response', responseType: 'json' });
    }

    /**
      * Function to receive a distinct model from server.
      */
    getModel(modelID: string): Observable<DBModel> {
        return this.http.get<DBModel>(this.modelURL + modelID, { observe: 'response', responseType: 'json' }, false);
    }

    /**
      * @param model
      * @param function_id function_id for the function the value should be returned
      */
    hasModelFunction(model: DBModel, function_id: string): boolean {
        if (!model) {
            return false;
        }
        for (let i = 0; i < model.functions.length; i++) {
            const function_object: DBFunction = model.functions[i];
            if (function_object.functionId === function_id) {
                return true;
            }
        }
        return false;
    }

    getModelFunctions(model: DBModel, regexp: RegExp): DBFunction[] | null {
        if (model === undefined) {
            return [];
        }
        return model.functions.filter(dbFunction => dbFunction.functionId.match(regexp));
    }

    getModelPortCount(model: DBModel): number {
        const functions = this.getModelFunctions(model, /\d+_PORT_LAYOUT_[A-Z]$/);
        if (functions.length === 0) {
            return 0;
        }
        const split = functions[0].functionId.split('_PORT_LAYOUT_');
        const portCount = Number(split[0]); // Get port count
        return portCount;
    }

    getModelPortLayout(model: DBModel): number[][][] {
        const functions = this.getModelFunctions(model, /\d+_PORT_LAYOUT_[A-Z]$/);
        if (functions.length === 0) {
            return [];
        }
        const split = functions[0].functionId.split('_PORT_LAYOUT_');
        const layoutScheme = split[1]; // Get layout letter
        const portCount = Number(split[0]); // Get port count
        let result = [];
        // "A" stands for linear order, "B" stands for from down to up and left to right order
        switch (layoutScheme) {
            case 'A':
                if (portCount === 2) {
                    result = [
                        [
                            [0, 1]
                        ]
                    ];
                }
                if (portCount === 3) {
                    result = [
                        [
                            [0, 1, 2]
                        ]
                    ];
                }
                if (portCount === 8) {
                    result = [
                        [
                            [0, 1, 2, 3],
                            [4, 5, 6, 7],
                        ]
                    ];
                }
                if (portCount === 12) {
                    result = [
                        [
                            [0, 1, 2, 3],
                            [4, 5, 6, 7],
                            [8],
                            [10]
                        ],
                        [
                            [-1, -1, -1, -1], // Placeholder for non existing port
                            [-1, -1, -1, -1], // Placeholder for non existing port
                            [9],
                            [11]
                        ]
                    ];
                }
                break;

            case 'B':
                if (portCount === 12) {
                    result = [
                        [
                            [0, 2, 4, 6, 8, 10]
                        ],
                        [
                            [1, 3, 5, 7, 9, 11]
                        ]
                    ];
                }
                if (portCount === 28) {
                    result = [
                        [
                            [0, 2, 4, 6, 8, 10],
                            [12, 14, 16, 18, 20, 22],
                            [24, 26]
                        ],
                        [
                            [1, 3, 5, 7, 9, 11],
                            [13, 15, 17, 19, 21, 23],
                            [25, 27]
                        ]
                    ];
                }
                if (portCount === 52) {
                    result = [
                        [
                            [0, 2, 4, 6, 8, 10],
                            [12, 14, 16, 18, 20, 22],
                            [24, 26, 28, 30, 32, 34],
                            [36, 38, 40, 42, 44, 46],
                            [48, 50]
                        ],
                        [
                            [1, 3, 5, 7, 9, 11],
                            [13, 15, 17, 19, 21, 23],
                            [25, 27, 29, 31, 33, 35],
                            [37, 39, 41, 43, 45, 47],
                            [49, 51]
                        ]
                    ];
                }
                break;
        }
        return result;
    }

    /*
     * Get index number of all fwan ports on a router
     * @param model model with function properties for port numbers
     */
    getRouterFwanPorts(model: DBModel): number[] {
        const portNumbers = [];
        const fwanPortFunctions = this.getModelFunctions(model, /ROUTER_PORT\d_FWAN+$/);
        fwanPortFunctions.forEach(modelFunction => {
            portNumbers.push(Number.parseInt(modelFunction.functionId.substring(11, 12)));
        });
        return portNumbers.sort();
    }

    getModelForDevice(device: DBDevice): DBModel {
        return this.models.find(v => v.modelId.toLocaleLowerCase() === device.model.toLocaleLowerCase());
    }

    isModelRouter(model: DBModel): boolean {
        return model.mode === 'ROUTER';
    }

    isModelSwitch(model: DBModel): boolean {
        return model.mode === 'SWITCH';
    }

    isModelAP(model: DBModel): boolean {
        return model.mode === 'ACCESSPOINT';
    }
}
