import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../Login/authentication.service';
import { DBCustomerFunctionProperty } from '../../data/endooSpotDB_objects';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { EndooHttpService } from 'app/Services/endooHttp.service';

@Injectable()
export class CustomerFunctionPropertyService {
    private readonly customerURL = '/api/customers/';

    constructor(private http: EndooHttpService, private authService: AuthenticationService) { }

    getListCustomerFunctionProperties(function_ids: string[]): Observable<DBCustomerFunctionProperty[][]> {
        const customerFunctionPropertyRequests: Observable<DBCustomerFunctionProperty[]>[] = [];
        for (let i = 0; i < function_ids.length; i++) {
            const element: string = function_ids[i];
            customerFunctionPropertyRequests[i] = this.getCustomerFunctionProperties(element);
        }
        return forkJoin(customerFunctionPropertyRequests).pipe(map((result: DBCustomerFunctionProperty[][]) => {
            if (result !== null && result !== undefined) {
                return result;
            } else {
                return [];
            }
        }));
    }

    /**
     * Get values of all customerFunctionProperties
     * @param function_id function_id where all properties should be returned
     */
    getCustomerFunctionProperties(function_id: string): Observable<DBCustomerFunctionProperty[]> {
        return this.http.get<DBCustomerFunctionProperty[]>(this.customerURL + this.authService.getCustomerId() + '/functions/' + function_id.toString(), { observe: 'response', responseType: 'json' });
    }

    /**
     * Function to change customerFunctionProperty
     * @param function_id Function ID which should be changed
     * @param property_id Property ID which should be changed
     * @param value new Value of customerFunctionProperty
     */
    setCustomerFunctionProperty(function_id: string, property_id: string, value: string): Observable<void> {
        return this.http.post<void>(this.customerURL + this.authService.getCustomerId() + '/functions/' + function_id.toString() + '/properties/' + property_id.toString(), { value: value }, { observe: 'response', responseType: 'json' });
    }

    /**
     * Function to remove customerFunctionProperty
     * @param function_id Function ID which should be removed
     * @param property_id Property ID which should be removed
     */
    removeCustomerFunctionProperty(function_id: string, property_id: string): Observable<void> {
        return this.http.delete<any>(this.customerURL + this.authService.getCustomerId() + '/functions/' + function_id.toString() + '/properties/' + property_id.toString(), { observe: 'response', responseType: 'json' });
    }

    /**
     * Function to remove customerFunctionProperty
     * @param function_id Function ID which should be removed
     * @param property_id Property ID which should be removed
     */
    removeCustomerFunctionPropertyPossible(function_id: string, property_id: string): Observable<boolean> {
        return this.http.get<boolean>(this.customerURL + this.authService.getCustomerId() + '/functions/' + function_id.toString() + '/properties/' + property_id.toString() + '/deletePossible', { observe: 'response', responseType: 'json' });
    }

    /**
     * Function to find the value of customerFunctionProperty in array identified by function_id and property_id
     * @param cfps Array with the customer function Properties
     * @param function_id function_id for the function the value should be returned
     * @param property_id property_id for the function property the value should be returned
     */
    getCustomerFunctionPropertyValue(cfps: DBCustomerFunctionProperty[], function_id: string, property_id: string): string {
        for (let i = 0; i < cfps.length; i++) {
            const cfp: DBCustomerFunctionProperty = cfps[i];
            if (cfp.function === function_id && cfp.property === property_id) {
                return cfp.value;
            }
        }
        return undefined;
    }

    /**
     * Get values of all customerFunctionProperties
     * @param function_id function_id where all properties should be returned
     */
    getFunctionsByPropertyAndValue(property_name: string, value: string): Observable<DBCustomerFunctionProperty[]> {
        return this.http.get<DBCustomerFunctionProperty[]>(this.customerURL + this.authService.getCustomerId() +
            '/properties/' + property_name + '/' + value, { observe: 'response', responseType: 'json' });
    }
}
